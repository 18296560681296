/*
    @type: hook
    @name: useScrollDirection
    @use: Detects scroll/wheel direction.
 */

import {useState, useEffect} from "react";
import Scrollbar from "smooth-scrollbar";
import {isMobile} from "react-device-detect";

const SCROLL_UP = "up";
const SCROLL_DOWN = "down";

export default function useScrollDirection ({ initialDirection, thresholdPixels, off } = {}) {
    const [scrollDir, setScrollDir] = useState(initialDirection);
    const sb = Scrollbar.getAll()[0] || null;

    useEffect(() => {
        const threshold = thresholdPixels || 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            if (sb) {
                const scrollY = sb.scrollTop;

                if (Math.abs(scrollY - lastScrollY) < threshold) {
                    // We haven't exceeded the threshold
                    ticking = false;
                    return;
                }

                setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
                lastScrollY = scrollY > 0 ? scrollY : 0;
                ticking = false;
            }
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        /**
         * Bind the scroll handler if `off` is set to false.
         * If `off` is set to true reset the scroll direction.
         */

        if (!isMobile) {
            !off
                ? window.addEventListener("wheel", onScroll)
                : setScrollDir(initialDirection);
        } else {
            !off
                ? window.addEventListener("touchmove", onScroll)
                : setScrollDir(initialDirection);

            !off
                ? window.addEventListener("mousewheel", onScroll)
                : setScrollDir(initialDirection);
        }


        return () => {
            window.removeEventListener("mousewheel", onScroll);
            window.removeEventListener("touchmove", onScroll);
        }
    }, [initialDirection, thresholdPixels, off, sb]);

    return scrollDir;
};
