import React, {useEffect, useRef} from "react";
import {TweenMax, TimelineMax} from "gsap";
import {isMobile} from "react-device-detect";
import Container from "../Container";
import Images from "../Images";
import "lazysizes";

import styles from "./SpeakerHeader.module.scss";
import SpeakerNavigation from "./SpeakerNavigation";
import {useSelector} from "react-redux";
import Splitting from "splitting"
import ImageAnimation from "../../functions/ImageAnimation";

import useScrollDirection from "../../hooks/useScrollDirection";
import Navigation from "../../partials/Navigation";

const HeaderFigcaption = (name, description) => {
    return `
        <div data-splitting="lines">
            <h2 class="delete">${name}</h2>
            <p class="delete">${description}</p>
        </div>
    `
};

export default function SpeakerHeader({data, previous, next, speakerId, speakerIndex}) {
    const {sb} = useSelector(state => state);
    const direction = useScrollDirection();

    const backgroundImage = require(`../../images/speakers/${speakerId}/background.jpg`);

    const header = useRef(null);

    const imageInitialPos = useRef(0);
    const imagePrevPos = useRef(0);
    const textPrevPos = useRef(0);
    const reachedImagePos = useRef(0);


    useEffect(() => {
        const splitElements = document.querySelectorAll('[data-splitting]');

       setTimeout(() => {
           Object.values(splitElements).map(el => {
               if (!el.classList.contains('splitting')) {
                   const split = Splitting({
                       whitespace: true
                   });

                   split.map(item => {
                       const titleWrap = document.createElement('h2');
                       const textWrap = document.createElement('p');

                       item.el.appendChild(titleWrap);
                       item.el.appendChild(textWrap);

                       return item.lines.map((words, index) => {
                               const wrap = document.createElement('div');
                               if (index === 0 || index === 1) {
                                   titleWrap.appendChild(wrap);

                                   const mask = document.createElement('span');
                                   mask.classList.add(styles.TitleMask);

                                   TweenMax.set(mask, {
                                       left: index === 0 ? 0 : null,
                                       right: index === 1 ? 0 : null
                                   });

                                   wrap.appendChild(mask);

                                   TweenMax.set(wrap, {
                                       display: 'block',
                                       width: 'fit-content'
                                   })
                               } else {
                                   textWrap.appendChild(wrap);

                                   TweenMax.set(wrap, {
                                       opacity: 0,
                                       x: 20,
                                       y: 20,
                                       rotate: 10,
                                       transformOrigin: '-50% -10%'
                                   });
                               }

                               words.map((word, index) => {
                                   wrap.appendChild(word);

                                   if (index < words.length - 1) {
                                       const space = document.createElement('span');
                                       space.innerText = ' ';
                                       wrap.appendChild(space);

                                       const deleteElement = document.querySelectorAll('.delete');
                                       Object.values(deleteElement).map(el => el.outerHTML = '');
                                   }

                                   return true;
                               })

                               return true;
                           }
                       )
                   });
               }

               return true;
           });
       }, 1000)
    }, []);

    useEffect(() => {
        const figureMain = header.current.getElementsByClassName(styles.MainImage)[0];
        const pictureMain = figureMain.querySelector('picture');
        const figcaptionMain = figureMain.querySelector('figcaption');
        const imageMain = figureMain.querySelector('img');

        const figureJob = header.current.getElementsByClassName(styles.Job)[0];
        const pictureJob = figureJob.querySelector('picture');
        const figcaptionJob = figureJob.querySelector('figcaption');
        const imageJob = figureJob.querySelector('img');



        if (imageInitialPos.current === 0) {
            TweenMax.set([imageMain, imageJob], {
                opacity: 0,
                scale: .8
            });

            TweenMax.set([figcaptionMain, figcaptionJob], {
                opacity: 0
            });

            if (!isMobile) {
                const lines = header.current.querySelectorAll('.splitting>p>div').length + 2;
                const newHeight = header.current.getBoundingClientRect().height + (lines * 10)

                TweenMax.set(header.current, {
                    //height: newHeight
                })
            }
        }

        const handleScroll = (status) => {
            const figureMainRect = figureMain.getBoundingClientRect();

            if (imageInitialPos.current === 0) {
                imageInitialPos.current = status.offset.y + figureMainRect.bottom;
            }

            const background = document.getElementById('speakersBackground');
            const backgroundGradient = document.getElementById('speakersBackgroundGradient');

            const topOffset = isMobile ? 100 : 200;

            if (sb.isVisible(pictureMain)) {
                TweenMax.set(background, {
                    backgroundImage: `url(${backgroundImage})`,
                });

                if (
                    header.current.getBoundingClientRect().top <= (window.innerHeight / 2 + (isMobile ? 50 : 100))
                    && header.current.getBoundingClientRect().bottom >= window.innerHeight - (isMobile ? 50 : 100)
                ) {
                    document.body.classList.add('bg-color--0');

                    new TimelineMax()
                        .set(backgroundGradient, {
                            background: `linear-gradient(180deg, transparent 0%, ${data.color} 100%)`
                        })
                        .to(background, 1.5, {
                            opacity: 1
                        })

                } else {
                    document.body.classList.remove('bg-color--0');
                    TweenMax.to(background, 1.5, {
                        opacity: 0
                    });
                }

                ImageAnimation(pictureMain, figureMain, pictureMain, figcaptionMain, imageMain, () => {
                    const titleMasks = header.current.getElementsByClassName(styles.TitleMask);

                    if (titleMasks.length > 0) {

                        Object.values(titleMasks).map((mask, index) =>
                            new TimelineMax()
                                .to(mask, .2, {
                                    width: '100%',
                                })
                                .set(mask.nextElementSibling, {
                                    opacity: 1
                                })
                                .to(mask, .2, {
                                    left: index === 0 ? '100%' : 'initial',
                                    right: index === 1 ? '100%' : 'initial',
                                    delay: .2,
                                })
                        )

                        setTimeout(() => {
                            const elements = figcaptionMain.querySelectorAll('p div');

                            Object.values(elements).map((el, index) =>
                                TweenMax.to(el, .4, {
                                    opacity: 1,
                                    y: 0,
                                    x: 0,
                                    rotate: 0,
                                    delay: 0.1 * index
                                })
                            );
                        }, 400)
                    }
                }, false, sb);

                if (pictureJob) ImageAnimation(figureJob.getElementsByClassName(styles.JobImage)[0], figureJob, pictureJob, figcaptionJob, imageJob, null, false, sb);

                if (direction === 'down') {
                    if (!figureMain.classList.contains('stuck') && !figureMain.classList.contains('reached')) {

                        if (imagePrevPos.current === 0) {
                            imagePrevPos.current = status.offset.y;
                            textPrevPos.current = status.offset.y;
                        }

                        if (figureMainRect.top <= topOffset) {
                            imagePrevPos.current = status.offset.y;
                            textPrevPos.current = status.offset.y;
                            figureMain.classList.add('stuck');
                            figcaptionMain.classList.add('stuck');
                        }
                    } else {
                        if (figcaptionMain.getBoundingClientRect().top <= 100) {
                            figureMain.classList.remove('stuck');
                            figcaptionMain.classList.remove('stuck');
                            figureMain.classList.add('reached');
                            figcaptionMain.classList.add('reached');

                            if (reachedImagePos.current === 0) reachedImagePos.current = figureMainRect.bottom;
                        }
                    }
                } else {
                    if (!figureMain.classList.contains('stuck') && figureMain.classList.contains('reached')) {
                        if (figureMainRect.bottom >= reachedImagePos.current) {
                            figureMain.classList.add('stuck');
                            figcaptionMain.classList.add('stuck');
                            figureMain.classList.remove('reached');
                            figcaptionMain.classList.remove('reached');
                        }
                    } else {
                        if (figureMainRect.top >= topOffset) {
                            figureMain.classList.remove('stuck');
                            figcaptionMain.classList.remove('stuck');

                            TweenMax.set(figureMain, {
                                clearProps: 'y'
                            });
                        }
                    }
                }

                if (figureMain.classList.contains('stuck')) {
                    const posY = -(status.offset.y - imagePrevPos.current) * (isMobile ? -.1 : -.6);

                    TweenMax.set(figureMain, {
                        y: posY > 0 ? posY : 0
                    });
                }

                if (figcaptionMain.classList.contains('stuck')) {
                    TweenMax.set(figcaptionMain, {
                        // y: -(status.offset.y - textPrevPos.current) * (isMobile ? .2 : .4)
                        y: -(status.offset.y - textPrevPos.current)
                    });
                }
            } else {
                if (pictureMain.getBoundingClientRect().top > window.innerHeight) {
                    TweenMax.to(imageMain, .4, {
                        opacity: 0,
                        scale: .8,
                        y: 0
                    })

                    TweenMax.to(figcaptionMain, {
                        opacity: 0,
                        y: 0
                    })
                }
                figureMain.classList.remove('stuck');
                figcaptionMain.classList.remove('stuck');

            }
        };

        if (sb) sb.addListener(handleScroll);

        return (() => {
            if (sb) sb.removeListener(handleScroll);
        })
    }, [sb, direction, backgroundImage, data.color]);

    return (
        <header className={styles.Main}
                ref={header}>

            <Container>
                {
                    !isMobile ?
                        <SpeakerNavigation {...{speakerId, previous, next}}/>
                        : <Navigation active={speakerIndex}/>
                }

                <Images url={require(`../../images/speakers/${speakerId}/header.jpg`)}
                        classname={styles.MainImage}
                        pictureSize={{height: 800}}
                        figcaption={HeaderFigcaption(data.name, data.description)}>
                    <source type={'image/webp'}
                            data-srcset={`${require(`../../images/speakers/${speakerId}/header@2x.webp`)} 2x, ${require(`../../images/speakers/${speakerId}/header.webp`)} 1x`}/>
                </Images>

                <div className={styles.Job}>
                    {
                        !isMobile ?
                            <Images url={require(`../../images/speakers/${speakerId}/job.jpg`)}
                                    classname={styles.JobImage}
                                    figcaption={`<span>${data.job}</span>`}>
                                <source type={'image/webp'}
                                        data-srcset={`${require(`../../images/speakers/${speakerId}/job@2x.webp`)} 2x, ${require(`../../images/speakers/${speakerId}/job.webp`)} 1x`}/>
                            </Images>
                            : <span>{data.job}</span>
                    }

                </div>

            </Container>
        </header>
    )
}
