import React from "react";

export default function Whatsapp() {
    const picto = `
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M13,0 C5.81451699,0 0,5.8112925 0,13 C0,15.7110622 0.826754988,18.2187623 2.29531266,20.3734473 C2.40604512,20.5375515 2.43595477,20.7431744 2.37656266,20.9320247 L1.05625016,25.3500098 L5.38281266,23.8570247 C5.57014682,23.7946595 5.7756724,23.8208233 5.94140641,23.9281348 C7.96795691,25.2430848 10.3940072,26.0000098 13,26 C20.1888867,26.0000098 26.0000002,20.1888798 26,13 C26.0000002,5.8110975 20.1888867,0 13,0 L13,0 Z M7.67812516,5.6671875 L10.0445314,9.57736 L9.05937516,10.55236 C8.85320566,10.7603813 8.81161907,11.0805913 8.95781266,11.334375 C10.4612045,13.9378298 12.242484,15.9632623 14.7367189,17.0625098 C14.9813376,17.171174 15.2675572,17.1187741 15.4578127,16.9304948 L16.5039064,15.8843848 L20.3226564,18.2711198 C19.8998025,19.2227847 19.3485502,19.7893898 18.7179689,20.1195247 C17.9875542,20.5019847 17.0954262,20.5743947 16.0773439,20.3632747 C14.0411835,19.9410672 11.5816582,18.3407997 9.57734391,16.2906347 C7.57303024,14.2405022 6.02197391,11.7475475 5.62656266,9.719515 C5.42885866,8.705515 5.51619916,7.83224 5.89062516,7.13986 C6.21301866,6.5437125 6.76064366,6.0333325 7.67812516,5.6671875 L7.67812516,5.6671875 Z" fill="#FFFFFF"/>
    </g>
</svg>`
    return (
        <i dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
