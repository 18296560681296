import React, {useState, useEffect, useCallback, useRef} from "react";
import {TweenMax} from "gsap";
import {isIE} from "react-device-detect";

import Visualizer from "./Visualizer";
import PauseIcon from "../Pictos/PauseIcon";
import PlayIcon from "../Pictos/PlayIcon";

import styles from "./Player.module.scss";
import CloseIcon from "../Pictos/CloseIcon";

//turn 128 seconds into 2:08
function getTimeCodeFromNum(num) {
    let seconds = parseInt(num);
    let minutes = parseInt(seconds / 60);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
        seconds % 60
    ).padStart(2, 0)}`;
}



export default function Player({data, speakerId, closeFn}) {
    const [playing, setPlaying] = useState(null);
    const [visualizer, setVisualizer] = useState(false);

    const audio = useRef(null);
    const player = useRef(null);
    const timeline = useRef(null);
    const backTimeline = useRef(null);

    const interval = useRef(null);

    //Progress Bar
    const progressBarHandler = useCallback(() => {

        if (playing) {
            //check audio percentage and update time accordingly
            interval.current = setInterval(() => {
                const progressBar = player.current.getElementsByClassName(styles.Progress)[0];

                if (progressBar) {
                    TweenMax.to([progressBar, backTimeline.current], .2, {
                        width:  audio.current.currentTime / audio.current.duration * 100 + "%"
                    });

                    player.current.querySelector(`.${styles.Time} .current`).textContent = `${getTimeCodeFromNum(
                        audio.current.duration - audio.current.currentTime
                    )}`;
                }
            }, 500);
        } else {
            clearInterval(interval.current)
        }

    }, [playing]);

    useEffect(() => {
        TweenMax.to(player.current, .5, {
            top: 'inherit',
            bottom: 0
        });

        progressBarHandler();
    }, [playing, progressBarHandler]);

    //Play handler
    useEffect(() => {
        if (playing === false) {
            audio.current.pause();
        } else if (playing === true) {
            audio.current.play();

            if (!visualizer) {
                setVisualizer(() => true);
            }
        }
    }, [playing, visualizer]);


    return (
        <React.Fragment>
            {
                !isIE && visualizer ? <Visualizer {...{data}} selector={'Visualizer-'+speakerId}/> : null
            }
            <div className={styles.backTimeline} style={{backgroundColor: data.color}} ref={backTimeline}/>

            <div className={styles.Main} ref={player}>
                <audio ref={audio} id={'Visualizer-' + speakerId}
                       controls hidden={true}
                       onPlay={() => setPlaying(() => true)}
                       onPause={() => setPlaying(() => false)}
                       autoPlay={false}>
                    <source src={require(`../../audio/${speakerId}/audio.mp3`)}
                            type="audio/mp3"/>
                </audio>

                <div className={styles.Player}>
                    <h3 className={styles.Name}>{data.name}</h3>
                    <p className={styles.Title}>{data.audio.title}</p>

                    <div className={styles.TimelineWrapper}>
                        <div className={styles.Timeline} ref={timeline} onMouseUp={(evt) => {
                            const offsetX = evt.clientX - timeline.current.getBoundingClientRect().left

                            //click on timeline to skip around
                            const timelineWidth = window.getComputedStyle(timeline.current).width;
                            audio.current.currentTime = offsetX / parseInt(timelineWidth) * audio.current.duration;
                        }}>
                            <div className={styles.Progress}><i className={styles.ProgressDot}/></div>
                        </div>

                        <div className={styles.Time}>
                            <div className="current">0:00</div>
                        </div>
                    </div>
                    <div className={styles.Controls}>
                        <button id={'playerButton'} onClick={() => setPlaying((prev) => !prev)}>
                            {
                                playing ? <PauseIcon/> : <PlayIcon classname={`player-${speakerId}`}/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
