import React, {useEffect, useRef} from "react";

import styles from "./Visualizer.module.scss";

const AudioContext = window.AudioContext          // Default
    || window.webkitAudioContext;  // Safari and old versions of Chrome

export default function Visualizer({data, selector}) {
    const canvas = useRef(null);

    useEffect(() => {
        const audio = document.getElementById(selector);

        console.log(canvas.current.closest('div').getBoundingClientRect().height)

        const WIDTH = canvas.current.closest('div').getBoundingClientRect().width;
        const HEIGHT = canvas.current.closest('div').getBoundingClientRect().height;

        canvas.current.width = WIDTH;
        canvas.current.height = HEIGHT;

        const  ctx = canvas.current.getContext("2d");

        audio.load();
        //audio.play();

        const context = new AudioContext();
        const src = context.createMediaElementSource(audio);
        const analyser = context.createAnalyser();
        src.connect(analyser);

        if (context.state === 'suspended') {
            setTimeout(() => audio.pause(), 300)

            const playButton = audio.nextElementSibling.querySelector(`#playerButton`);

            playButton.addEventListener('click', () => context.resume(), false);

            // audio.pause();
        }

        analyser.connect(context.destination);

        analyser.fftSize = 256;

        const bufferLength = analyser.frequencyBinCount;

        const dataArray = new Uint8Array(bufferLength);


        const barWidth = (WIDTH / bufferLength) * 1.2;
        let barHeight;
        let x = 0;

        function renderFrame() {
            requestAnimationFrame(renderFrame);

            x = 0;

            analyser.getByteFrequencyData(dataArray);

             ctx.clearRect(0, 0, WIDTH, HEIGHT);

            for (let i = 0; i < bufferLength; i++) {
                barHeight = dataArray[i] / 2;

                 ctx.fillStyle = '#FFF';
                 ctx.globalAlpha = 1;
                 ctx.fillRect((WIDTH / 2) - x, (HEIGHT / 2.3) - (barHeight / 2), barWidth, barHeight);

                if (i >= 1) {
                     ctx.fillRect((WIDTH / 2) + x, (HEIGHT / 2.3) - (barHeight / 2), barWidth, barHeight);
                }

                x += barWidth + 1;
            }
        }

        renderFrame();
    }, [selector])
    return (
        <canvas className={styles.Main} ref={canvas}/>
    )
}
