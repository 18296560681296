import React, { useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {TweenMax} from "gsap";
import Speakers from "../data/Speakers";

import styles from "./Navigation.module.scss";
import onScreen from "../functions/onScreen";

export default function Navigation ({scrolled}) {
    const {sb} = useSelector(state => state);

    const navigation = useRef(null);

    useEffect(() => {
        if (!isMobile) {
            TweenMax.set(navigation.current, {
                marginTop: -(navigation.current.getBoundingClientRect().height / 2)
            })
        }
    }, []);

    //Scroll Handler
    useEffect(() => {
        const sections = document.querySelectorAll('[data-section]');

        const handleScroll = (status) => {
            Object.values(sections).map((section, index) => {
                const sectionActive = document.querySelector('section.active');
                const navActive = navigation.current.querySelector('li.active');

                if (onScreen(section, true) && !section.classList.contains('active')) {
                    if (sectionActive) {
                        sectionActive.classList.remove('active');
                        navActive.classList.remove('active');
                    }
                    section.classList.add('active');
                    navigation.current.querySelectorAll('li')[index].classList.add('active');
                }

                return null;
            });

            TweenMax.set(navigation.current, {
                top: !isMobile ? (190 + status.offset.y) : status.offset.y
            });
        };

        if (sb) {
            sb.addListener(handleScroll);
        }

        return (() => {
            if (sb) sb.removeListener(handleScroll);
        });
    },[sb]);

    return (
        <nav className={styles.Main} ref={navigation}>
            <ul>
                <li/>

                {
                    Speakers().map((el, index) =>
                        <li key={index}/>
                    )
                }
            </ul>
        </nav>
    )
}
