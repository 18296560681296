import React from "react";

export default function Linkedin () {
    const picto = `
<svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill="#FFFFFF">
            <path d="M0.216473657,16.2608696 L3.85996601,16.2608696 L3.85996601,5.28899024 L0.216473657,5.28899024 L0.216473657,16.2608696 Z M2.06248765,-7.10542736e-15 C0.815580412,-7.10542736e-15 0,0.818697169 0,1.89531184 C0,2.94846508 0.791984507,3.79200842 2.01411011,3.79200842 L2.03845698,3.79200842 C3.30951346,3.79200842 4.09999605,2.94862333 4.09999605,1.89531184 C4.07604443,0.818697169 3.30951346,-7.10542736e-15 2.06248765,-7.10542736e-15 L2.06248765,-7.10542736e-15 Z M17,9.96952622 L17,16.2608696 L13.3563496,16.2608696 L13.3563496,10.3908825 C13.3563496,8.91629386 12.8293348,7.90994479 11.5104541,7.90994479 C10.503656,7.90994479 9.9045097,8.58815032 9.64092328,9.24420002 C9.5448006,9.47873521 9.5200585,9.80517751 9.5200585,10.1334793 L9.5200585,16.2608696 L5.8751828,16.2608696 C5.8751828,16.2608696 5.92427177,6.31903812 5.8751828,5.28899024 L9.51970278,5.28899024 L9.51970278,6.84421022 C9.51231177,6.85584202 9.5027074,6.8683838 9.49575116,6.87965953 L9.51970278,6.87965953 L9.51970278,6.84421022 C10.0039129,6.09767772 10.8686613,5.03123099 12.804237,5.03123099 C15.2022845,5.03119143 17,6.59938839 17,9.96952622 L17,9.96952622 Z" />
        </g>
    </g>
</svg>`

    return (
        <i dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
