import React, {useEffect, useState, useRef} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {TweenMax} from "gsap";

import ShareIcon from "./Pictos/ShareIcon";

import styles from "./Share.module.scss"
import Twitter from "./Pictos/Social/Twitter";
import Facebook from "./Pictos/Social/Facebook";
import Email from "./Pictos/Social/Email";
import Linkedin from "./Pictos/Social/Lindekin";
import Whatsapp from "./Pictos/Social/Whatsapp";

export default function Share() {
    const {sb} = useSelector(state => state);

    const text = 'Ils sont chef, architecte, designer, et directeur artistique renommés. Nous les avons rencontrés pour célébrer les nouveaux modèles Code 11.59 by Audemars Piguet. Chacun à leur façon, ils incarnent le mélange subtil de classicisme et de modernité qui caractérise la collection.';
    const title = 'Classic with a Twist - Audemars Piguet | Les Echos';
    const url = process.env.PUBLIC_URL;

    const twitterText = 'Ils sont chef, architecte, designer, et directeur artistique renommés. Nous les avons rencontrés pour célébrer les nouveaux modèles Code 11.59 by Audemars Piguet.';

    const [nav, setNav] = useState(false);
    const navRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            TweenMax.to(navRef.current, 1, {
                opacity: 1
            })
        };

        if (sb && isMobile) sb.addListener(handleScroll);

        return (() => {
            if (sb && isMobile) sb.removeListener(handleScroll);
        })
    }, [sb]);

    return (
        <nav className={[styles.Main, nav ? styles.Open : ''].join(' ')} ref={navRef} style={ isMobile ? {opacity: 0} : null}>
            <div className={styles.Icon} onClick={() => setNav((prev) => !prev)}>
                <ShareIcon/>
            </div>

            <ul>
                <li>
                    <a href={`https://twitter.com/intent/tweet/?text=${twitterText}&url=${url}`} title={'Partager sur Twitter'} target={'_blank'}>
                        <Twitter/>
                    </a>
                </li>
                <li>
                    <a href={`https://facebook.com/sharer/sharer.php?u=${url}`} title={'Partager sur Facebook'} target={'_blank'}>
                        <Facebook/>
                    </a>
                </li>
                <li>
                    <a href={`mailto:?subject=${text}&body=${url}`} title={'Partager par E-mail'} target={'_blank'}>
                        <Email/>
                    </a>
                </li>
                <li>
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${title}&source=${url}`} title={'Partager sur Linkedin'} target={'_blank'}>
                        <Linkedin/>
                    </a>
                </li>
                <li>
                    <a href={`whatsapp://send?text=${encodeURIComponent(url)}`} data-action="share/whatsapp/share" title={'Partager sur Whatsapp'} target={'_blank'}>
                        <Whatsapp/>
                    </a>
                </li>
            </ul>
        </nav>
    )
}
