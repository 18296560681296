import React from "react";

export default function ArrowIcon({direction, classname}) {
    const picto = `<svg width="10px" height="34px" viewBox="0 0 10 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="desktop-AP-home-edito" transform="translate(-115.000000, -2443.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path id="Path-2" d="M119.5,2467.5 L119.5,2443 L120.5,2443 L120.5,2467.5 L124.5,2467.5 L120,2476.5 L115.5,2467.5 L119.5,2467.5 Z"></path>
        </g>
    </g>
</svg>`;

    const styles =
        direction === 'up' ?
            {
                display: 'inline-block',
                transformOrigin: 'center center',
                transform: `rotate(180deg)`
            }
            : null

    return (
        <i className={classname || ''} style={styles} dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
