import React from "react";
import SpeakerHeader from "./SpeakerHeader";
import SpeakerContent from "./SpeakerContent";

export default function Speaker({data, previous, next, speakerId, speakerIndex}) {
    return (
        <section data-section={speakerId}>
            <SpeakerHeader {...{data, previous, next, speakerId, speakerIndex}}/>

            <SpeakerContent {...{data, speakerId}}/>
        </section>
    )
}
