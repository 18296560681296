/*
    @type: Component
    @name: Image.js
    @params:
        - children (sources of the picture)
        - classname (custom component class, given by parent),
        - url (url of the default image)
        - alt (string of the alternate text of the image)
        - id (possible id of the image)
        - fullsize (indicates if the image as to show without taking care of the container)
    @description:
        Displays the images if a figure + picture html 5 tag
 */

//Dependencies
import React, {useEffect, useRef} from "react";
import {TweenMax} from "gsap";
import "lazysizes";

//Styles
import styles from "./Image.module.scss";
import {useSelector} from "react-redux";

export default function Images ({url, alt = 'image', classname, id = null, fullsize = false, children, figcaption = null, pictureSize, lazy = true}) {
    const {sb} = useSelector(state => state);

    const figure = useRef(null);
    const image = useRef(null);

    const pictureHeight = useRef(0);

    useEffect(() => {
        if (fullsize) {
            TweenMax.set(image.current, {
                marginLeft: -(figure.current.getBoundingClientRect().left),
                marginRight: -(figure.current.getBoundingClientRect().left),
            })
        }
    }, [fullsize]);

    useEffect(() => {
        if (pictureHeight.current === 0 && pictureSize) {
            TweenMax.set(image.current, {
                height: pictureSize.height,
                width: 'auto'
            });

            pictureHeight.current = pictureSize.height;
        }
    }, [pictureSize])

    useEffect(() => {
        const srcSet = figure.current.querySelectorAll('[data-srcset]');

        if (pictureSize && lazy) {
            const handleScroll = () => {
                if (sb.isVisible(figure.current)) {
                    TweenMax.set(image.current, {
                        clearProps: 'height,width'
                    });

                    Object.values(srcSet).map(el => el.removeAttribute('data-srcset'))
                }
            };

            if (sb) sb.addListener(handleScroll);

            return(() => {
                if (sb) sb.removeListener(handleScroll)
            })
        }
    }, [pictureSize, lazy, sb])

    return (
        <figure className={[styles.Picture, fullsize ? styles.FullSize : '', classname].join(' ')} id={id} ref={figure}>
            <picture>
                { children }
                <source data-srcset={url} type={'image/jpeg'}/>
                <img data-src={url} src={!lazy ? url : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='} alt={alt} className={lazy ? "lazyload" : ''} onError={() => {this.onerror=null; this.src=url}} ref={image}/>
            </picture>

            {
                figcaption ?
                    <figcaption dangerouslySetInnerHTML={{__html: figcaption}}/>
                :null
            }

        </figure>
    )
}
