import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import GoogleFontLoader from 'react-google-font-loader';

import Home from "./layout/Home";
import Player from "./components/Player/Player";


import "./scss/globals.scss";
import "./App.module.scss";
import styles from "./layout/Home.module.scss";
import Share from "./components/Share";

export default function App() {
    return (
        <React.Fragment>

            <GoogleFontLoader fonts={[
                {
                    font: 'Montserrat',
                    weights: [100, 400, 700]
                },
                {
                    font: 'Merriweather',
                    weights: [300, 400, 700]
                },
                {
                    font: 'Josefin Sans',
                    weights: [200]
                }
            ]}/>

            <Router>
                <Route
                    render={({location}) => (
                        <div className={'App'}>
                            <Home/>


                            <div id={'speakersBackground'} className={styles.Background}>
                                <div id={`speakersBackgroundGradient`} className={styles.Gradient}/>
                            </div>

                            <Share/>
                        </div>
                    )}
                />
            </Router>
        </React.Fragment>
    );
}
