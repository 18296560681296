import React from "react";

export default function Facebook() {
    const picto = `
<svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill="#FFFFFF">
            <path d="M6.01564694,18.75 L2.40722166,18.75 L2.40722166,9.375 L0,9.375 L0,6.14406738 L2.40722166,6.14304199 L2.40330993,4.23962402 C2.40330993,1.60374756 3.1373997,0 6.32632899,0 L8.98134403,0 L8.98134403,3.23151855 L7.32205366,3.23151855 C6.08022818,3.23151855 6.020499,3.6829834 6.020499,4.52574463 L6.01557172,6.14304199 L9,6.14304199 L8.64816951,9.37393799 L6.01805416,9.375 L6.01564694,18.75 Z"/>
        </g>
    </g>
</svg>`

    return (
        <i dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
