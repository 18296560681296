import {TweenMax} from "gsap";
import onScreen from "./onScreen";

export default function TextAnimation (element) {
    if (onScreen(element, true)) {
        TweenMax.to(element, .4, {
            opacity: 1
        })
    }
}
