import {isIE} from "react-device-detect"

export default function SpeakersData () {
    return [
        //language=HTML
        {
            name: "Mory Sacko",
            job: "Chef cuisinier",
            description: "Je suis un artisan, et je rapproche ce métier de cuisinier avec celui d’horloger. Notre but, c’est de transformer une matière ‘’",
            color: !isIE ? getComputedStyle(document.documentElement).getPropertyValue(`--speakerColor01`) : "#D2CCD7",
            introduction: {
                title: "Qui est Mory ?",
                text: "Mory Sacko, est populaire depuis sa participation à l’émission Top Chef 2020. Mais celui qui a fait ses classes au Mandarin Hôtel avec Thierry Marx, n’a pas attendu de passer à la télévision pour imaginer son propre restaurant. Mosuke, qu’il a ouvert le 1er septembre dans le 14ème arrondissement à Paris, est le fruit d’une mûre réflexion.&nbsp;«&nbsp;C’est un projet que j’avais en tête depuis plus d’un an et demi.&nbsp;»&nbsp;Son établissement privilégie les tons bleus tendres, les matières organiques, et promet une carte unique.&nbsp;«&nbsp;L’idée initiale est de créer un restaurant avec une offre culinaire totalement neuve. On s’amuse à mélanger les influences de trois territoires&nbsp;: la France, l’Afrique et le Japon.&nbsp;»"
            },
            columns: [
                {
                    text: [
                        "Mory a une vision précise et artistique de la cuisine. A travers ses dressages d’assiette, il cherche de nouveaux équilibres.&nbsp;«&nbsp;Il faut que l’assiette soit accessible et qu’on puisse identifier tous les produits, mais que la disposition des éléments soit travaillée. J’aime garder une pointe d’abstraction, je n’aime pas quand c’est trop figuratif.&nbsp;»&nbsp;Il aime surprendre&nbsp;: déstructurer, alterner les matières croustillantes et onctueuses, ou retravailler des basiques comme le riz sous forme de crème.",
                    ],
                    image: "player-poster",
                },
                {
                    text: [
                        "Pour lui les couleurs, les textures, sont autant d’éléments qui suscitent l’envie. Ses plats changent en fonction des saisons&nbsp;: l’hiver, il préfèrera le blanc, le printemps sera vert, et l’automne orangé.&nbsp;«&nbsp;Il y a un jeu de correspondance entre les coloris et la cuisine. Par exemple, il est vrai que les produits de la même couleur s’associent plus facilement.&nbsp;»&nbsp;Il déteste le criard, préfère la douceur des monochromes, relevés par une touche vive. Cette nuance discrète, entre en écho avec sa sensibilité."
                    ],
                    image: "clock",
                },
            ],
            fullsize: [
                "Il retrouve dans la montre Code 11.59 by Audemars Piguet, les mêmes ingrédients que dans son approche de la gastronomie. &nbsp;«&nbsp;Pour mes plats j’aime quelque chose de sobre et d’original de la même façon que pour une montre, la sobriété et l’élégance sont primordiales.&nbsp;»&nbsp;Il se sent proche d’un artisan.&nbsp;«&nbsp;Je rapproche ce métier de cuisinier avec celui d’horloger, parce qu’on a un même but&nbsp;: la transformation de la matière.&nbsp;»&nbsp;En cuisine, il porte une montre, car la maîtrise du temps est essentielle. Quand il travaille sa carte, Mory ne compte pas les heures&nbsp;: il aime s’isoler, le soir, pour créer.&nbsp;«&nbsp;Il y a cette notion de temps, et d’exigences qui habite tous les créatifs. Ce qui nous relie tous au fond, c’est la passion.&nbsp;»"
            ],
            audio: {
                title: "#Matière",
                file: "file.mp3"
            }
        },
        {
            name: "Franklin Azzi",
            job: "Architecte",
            description: "Le savoir-faire pour moi, c’est la plus grande richesse de tous les designers, ou des architectes ‘’",
            color: !isIE ? getComputedStyle(document.documentElement).getPropertyValue(`--speakerColor02`) : "#D8D7DF",
            introduction: {
                title: "Qui est Franklin ?",
                text: "Franklin Azzi est une figure de l’architecture, récemment nominé comme designer de l’année par le salon Maison & Objets 2020. Architecte autant qu’artisan, il aime penser le mobilier d’un lieu comme mener des projets d’urbanisme d’envergure. Le parisien se plaît à varier les échelles.&nbsp;«&nbsp;J’aime bien cet aller-retour temporel. Réussir à faire des projets très courts, très spontanés qui s’apparentent à des Polaroids de conception, comme des projets qui demandent des réflexions sur le long terme&nbsp;: sur l’usage de la ville, l’évolution de la société…&nbsp;»&nbsp;Son agence participe à la réhabilitation de la Tour Montparnasse au sein de la Nouvelle AOM (Franklin Azzi Architecture, Chartier Dalix Architectes, Hardel et Le Bihan Architectes), et veut transformer cet assemblage de bureau en lieu de vie où circule une énergie nouvelle.&nbsp;«&nbsp;L’important, c’est d’améliorer la vie des gens.&nbsp;»"
            },
            columns: [
                {
                    text: [
                        "Son métier demande une grande part de technicité.&nbsp;«&nbsp;On pourrait penser que la technique freine la créativité, au contraire elle peut être extrêmement créative.&nbsp;»&nbsp;Son talent, est d’unir subtilement deux pôles.&nbsp;«&nbsp;On a cette envie très forte de revenir à des fondamentaux aujourd’hui&nbsp;: à l’artisanat, à la matière, et en même temps on est dans une civilisation qui tend vers l’innovation, la technologie. C’est en mélangeant les deux que l’on va trouver une société d’hybridation, qui sera la société du futur.&nbsp;»"
                    ],
                    image: "player-poster",
                },
                {
                    text: [
                        "«&nbsp;Ma palette est très large, parce que je veux être contextuel.&nbsp;»&nbsp;Franklin adore s’imprégner de ce qui l’entoure&nbsp;: puiser son inspiration dans les espaces géographiques, les cultures. La philosophie de l’architecte, est de savoir s’adapter ; aux nouvelles matières environnementales entre autres. Bois, béton brut, textures végétales et matériaux vivants emplissent ses espaces, qui révèlent une modernité chaleureuse, un dosage savant entre patte industrielle et contours organique."
                    ],
                    image: "clock",
                },
            ],
            fullsize: [
                "Le temps est au cœur de son métier&nbsp;: certains de ses chantiers peuvent durer quinze ans. D’où son amour pour les montres.&nbsp;«&nbsp;C’est un objet qui m’a toujours fasciné, parce qu’il est d’une très grande précision. Cela demande un savoir-faire considérable.&nbsp;»&nbsp;La préservation de ce savoir-faire est pour lui essentielle.&nbsp;«&nbsp;C’est la plus grande richesse de tous les designers, ou des architectes. C’est la connaissance que l’on peut avoir sur les matières, les techniques de fabrication, qui va amener les designers à tendre vers une forme d’excellence.&nbsp;»&nbsp;"
            ],
            audio: {
                title: "#Savoir Faire",
                file: "file.mp3"
            }
        },
        {
            name: "Yorgo Tloupas",
            job: "Directeur artistique",
            description: "Le travail horloger est fascinant pour un designer, parce que tu retrouves de nombreuses similitudes avec notre pratique&nbsp;: la précision, le détail, l’assemblage des choses ‘’",
            color: !isIE ? getComputedStyle(document.documentElement).getPropertyValue(`--speakerColor03`) : "#615D5A",
            introduction: {
                title: "Qui est Yorgo ?",
                text: "Le directeur artistique Yorgo Tloupas est une figure emblématique du graphisme. Le parisien prolifique multiplie les projets, et infiltre sa vision esthétique dans de nombreux domaines. Il a signé la maquette des plus grands magazines français. Avec son studio Yorgo&Co, il pense l’empreinte visuelle de marques et grandes maisons de luxe. Sportif élégant, fan d’équipement raffiné, il participe au développement d’une marque de ski. Il a également ouvert son propre café – grec, hommage à ses origines, dans des teintes pistache et jaune tendre, que lui ont inspiré Athènes."
            },
            columns: [
                {
                    text: [
                        "Il voue une passion aux signes et aux symboles, et va puiser dans l’histoire antique pour penser des graphismes uniques, qui perdurent dans le temps. On lui reconnaît une maestria dans l’art du logo. Il prépare d’ailleurs une exposition sur le sujet au musée des Arts décoratifs de Paris en 2023.&nbsp;«&nbsp;Il y a un vrai savoir-faire dans la façon de dessiner un logo – ceux des enseignes de magasins par exemple. Si cela est mal fait, cela peut dénaturer des paysages. Il est important de faire appel à de vrais experts pour les concevoir.&nbsp;»"
                    ],
                    image: "player-poster",
                },
                {
                    text: [
                        "Pour lui, dans la création, il faut savoir manier les teintes avec intelligence.&nbsp;«&nbsp;Il faut se méfier des clichés comme quoi le rouge incarne le pouvoir, le bleu, la sérénité… C’est très subtil le choix des couleurs, la façon de faire passer un message à travers elles. On peut complètement détourner leurs codes.&nbsp;»"
                    ],
                    image: "clock",
                },
            ],
            fullsize: [
                "Yorgo collabore avec la manufacture Audemars Piguet en participant à l’élaboration de son image.&nbsp;«&nbsp;Le travail horloger est fascinant pour un designer, parce qu’on y retrouve de nombreuses similitudes avec notre pratique&nbsp;: la précision, le détail, l’assemblage des choses. Ce sont des couches, des superpositions et des imbrications d’éléments assez précis, qui font qu’au final on a un résultat de qualité.&nbsp;»&nbsp;Pour lui un travail créatif est réussi lorsqu’il porte une simplicité, une évidence, et que les heures de labeur passées à le peaufiner ne sont plus visibles.&nbsp;«&nbsp;Dans l’idéal, je préfère que mon métier s’efface, pour améliorer la lecture et la compréhension des autres.&nbsp;»",
            ],
            audio: {
                title: "#La couleur",
                file: "file.mp3"
            }
        },
        {
            name: "Joris Poggioli",
            job: "Architecte-Designer",
            description: "Je pense que le geste parfait n’existe pas du premier coup. <br/>Je pense que tout est répétition de geste précis ‘’",
            color: !isIE ? getComputedStyle(document.documentElement).getPropertyValue(`--speakerColor04`) : "#A8162C",
            introduction: {
                title: "Qui est Joris ?",
                text: "Joris Poggioli aime se présenter comme&nbsp;«&nbsp;un architecte qui travaille comme un designer, mais à l’échelle d’un artiste.&nbsp;»&nbsp;Le créatif franco-italien conçoit des objets et meubles inédits depuis 2017, via son studio Youth Edition. Récemment, il a présenté sa collection&nbsp;«&nbsp;Enamorados&nbsp;»&nbsp;dans une villa virtuelle. Il a été élu parmi les 100 designers de l’année par le magazine AD. Cendrier en marbre Verde Alpi, tables de chevets en travertin, console Aglaé en marbre, lampe&nbsp;«&nbsp;bulles&nbsp;»&nbsp;en plâtre… Ses créations sculpturales épousent des courbes arrondies, elles offrent un œil voluptueux et contemporain au classicisme italien."
            },
            columns: [
                {
                    text: [
                        "Joris voue une passion à l’artisanat.&nbsp;«&nbsp;J’ai grandi dans les ateliers de mon père qui était serrurier. Mon oncle lui était sculpteur, il travaillait le marbre&nbsp;: cela m’a appris le côté manuel du métier, en parallèle de ma casquette plus théorique de designer.&nbsp;»&nbsp;C’est ce savoir-faire ancestral qu’il admire dans l’horlogerie.&nbsp;«&nbsp;Ce qui me fascine dans les montres c’est la fabrication. Il faut une minutie infime. Et puis il y a la croisée des matériaux&nbsp;: de l’acier, du tissu, du cuir, tout cela allié au côté technologique… Aujourd’hui ce qui est intéressant, c’est que dans l’artisanat et dans la technique, tout se croise.&nbsp;»"
                    ],
                    image: "player-poster",
                },
                {
                    text: [
                        "Chez lui, la froideur du marbre s’adoucit, le travertin devient vivant. Le jeu des matières est une obsession du designer, qui les travaille à contre-pied et les assemble avec tendresse, explorant leur subtilité. Il aime les débusquer chez des fabricants rares.&nbsp;«&nbsp;Avant de penser couleur, moi je pense matière&nbsp;: je suis un mauvais coloriste, mais associer des matières m’est tout à fait naturel. Il y a tant de nuances dans des matériaux comme l’ébène, par exemple, c’est passionnant.&nbsp;»"
                    ],
                    image: "clock",
                },
            ],
            fullsize: [
                "Il croit en la valeur du temps passé&nbsp;: là se loge la beauté d’un objet. Pour lui, l’excellence, c’est cette répétition qu’il applique à son travail. C’est continuer encore et encore, à peaufiner son geste, son dessin, pour que soudain, la courbe&nbsp;«&nbsp;parfaite&nbsp;»&nbsp;apparaisse ; celle que l’on ne peut plus retoucher.&nbsp;«&nbsp;Le savoir-faire c’est quelque chose que tu as appris, et que tu as répété avec le temps. Le geste parfait n’existe pas du premier coup. Je pense que tout est répétition de geste précis."
            ],
            audio: {
                title: "#Technique",
                file: "file.mp3"
            }
        }
    ];
}
