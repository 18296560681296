import React from "react";

export default function PauseIcon () {
    const picto = `
<svg width="16px" height="35px" viewBox="0 0 16 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-11" stroke="#000000">
            <path d="M0.5,-4.54747351e-13 L0.5,34.6546186" />
            <path d="M15.5,-4.54747351e-13 L15.5,34.6546186" />
        </g>
    </g>
</svg>`;

    return (
        <i dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
