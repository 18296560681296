import React, {useEffect, useRef} from "react";
import {TweenMax} from "gsap";
import {isMobile} from "react-device-detect";

import styles from "./Pictos.module.scss";
import {useSelector} from "react-redux";
import onScreen from "../../functions/onScreen";

export default function ScrollIcon() {
    const {sb} = useSelector(state => state);
    const arrow = useRef(null);

    const picto = `
<svg width="10px" height="103px" viewBox="0 0 10 103" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-4.000000, -4.000000)" fill="#FFFFFF">
            ${
                !isMobile ? 
                    `<text transform="translate(8.500000, 29.000000) rotate(-90.000000) translate(-8.500000, -29.000000) " font-family="Montserrat-Medium, Montserrat" font-size="13" font-weight="400"><tspan x="-20" y="34">SCROLL</tspan></text>` : null
            }
            <path id="Path-2" d="M8,98 L8,73.5 L9,73.5 L9,98 L13,98 L8.5,107 L4,98 L8,98 Z" fill-rule="nonzero"/>
        </g>
    </g>
</svg>`;

    useEffect(() => {
        const handleScroll = (status) => {
            TweenMax.to(arrow.current, .4, {
                opacity: status.offset.y > 0 ? 0 : 1
            })
        };

        if (sb) {
            sb.addListener(handleScroll);
        }

        return (() => {
            if (sb) sb.removeListener(handleScroll)
        })
    }, [sb]);

    return (
        <i onClick={() => {
            if (sb) {
                sb.scrollTo(0, window.innerHeight, 600)
            }
        }} className={styles.Scroll} dangerouslySetInnerHTML={{__html: picto}} ref={arrow}/>
    )
}
