import React, {useEffect, useState, useRef} from "react";
import slugify from "slugify";
import {TweenMax, TimelineMax, Power4} from "gsap";
import {isMobile} from "react-device-detect";

import SpeakersData from "../data/Speakers.js";
import Images from "../components/Images";
import Container from "../components/Container";

import EchosLogo from "../images/logos/les-echos-sponsors.svg";
import AudemarsLogo from "../images/logos/audemars-piguet.svg";

import styles from "./Masthead.module.scss";
import Navigation from "./Navigation";
import {useSelector} from "react-redux";
import ScrollIcon from "../components/Pictos/ScrollIcon";
import Share from "../components/Share";

const respSize = (val) => {
    return `${val * 100 / (isMobile ? window.innerWidth : 1440)}%`
}

export default function Masthead() {
    const {sb} = useSelector(state => state);

    const [activeSpeaker, setActiveSpeaker] = useState(3);
    const footer = useRef(null);
    const title1 = useRef(null);
    const title2 = useRef(null);
    const twist = useRef(null);
    const masthead = useRef(null);

    const imagePosition = {
        desktop: [
            {
                top: 226,
                left: respSize(540),
                zIndex: 3
            },
            {
                top: 67,
                left: respSize(740),
                zIndex: 0
            },
            {
                top: 388,
                left: respSize(642),
                zIndex: 4
            },
            {
                top: 266,
                left: respSize(362),
                zIndex: 1
            },
        ],
        mobile: [
            {
                top: 173,
                left: respSize(37),
                width: respSize(228),

                zIndex: 3
            },
            {
                top: 128,
                right: 0,
                width: respSize(205),

                zIndex: 0
            },
            {
                top: 374,
                right: respSize(20),
                width: respSize(210),

                zIndex: 4
            },
            {
                top: 420,
                left: 0,
                width: respSize(205),

                zIndex: 1
            },
        ]
    };

    useEffect(() => {
        if (sb) {
            if (sb.offset.y < 50) {
                document.body.removeAttribute('class');
                document.body.classList.add(`bg-color--${activeSpeaker}`);
            }
        }
    }, [activeSpeaker, sb]);

    useEffect(() => {
        const handleScroll = (status) => {
            if (sb.isVisible(masthead.current)) {
                if (status.offset.y >= 50) {
                    if (document.body.classList.length > 0) {
                        const color = getComputedStyle(document.body).backgroundColor;
                        document.body.removeAttribute('class');

                        TweenMax.set([title1.current, title2.current, twist.current], {
                            color: color
                        })
                    }
                } else {
                    document.body.classList.add(`bg-color--${activeSpeaker}`);
                    TweenMax.set([title1.current, title2.current], {
                        clearProps: 'color'
                    })
                }
            }
        };

        if (sb) {
            sb.addListener(handleScroll);
        }

        return (() => {
            if (sb) sb.removeListener(handleScroll);
        });
    }, [activeSpeaker, sb]);

    useEffect(() => {
        TweenMax.to(footer.current, .4, {
            opacity: 1,
            delay: 1
        })

        const footerPosition = () => {
            TweenMax.set(footer.current, {
                top: (window.innerHeight + sb.offset.y) - footer.current.getBoundingClientRect().height
            })
        };


        if (sb) {
            footerPosition();
            sb.addListener(footerPosition);
            window.addEventListener('resize', footerPosition, false);
        }

        return (() => {
            if (sb) sb.removeListener(footerPosition);
        });
    }, [sb]);

    useEffect(() => {
        TweenMax.set([title1.current, title2.current], {
            y: 0,
            x: 0,
            rotate: 5,
            transformOrigin: '-50% -30%'
        });

        const images = document.getElementsByClassName(styles.MosaicImage);

        TweenMax.to(images, 1, {
            opacity: .1,
            delay: .5,
            ease: Power4.easeIn,
            onComplete: () => {
                Object.values(images).map((item, index) =>
                    TweenMax.to(item, 2, {
                        opacity: 1,
                        delay: .2 * (index + 1),
                        onComplete: () => {
                            if (index === images.length - 1) {
                                TweenMax.to([title1.current, title2.current], .5, {
                                    opacity: 1,
                                    y: 1,
                                    x: 1,
                                    rotate: 0,
                                    onComplete: () => {
                                        new TimelineMax()
                                            .to(twist.current, .3, {
                                                rotate: 180
                                            }).to(twist.current, .3, {
                                            rotate: 0,
                                            delay: .5
                                        })
                                    }
                                })
                            }
                        }
                    })
                )
            }
        })
    }, []);

    const hoverPortrait = (e, index) => {
        setActiveSpeaker(() => index + 1);

        const target = e.target.closest(`.${styles.MosaicImage}`);

        TweenMax.set(target, {
            zIndex: 5,
            onComplete: () => {
                TweenMax.to(target, .3, {
                    scale: 1.05
                })
            }
        })
    };

    return (
        <section className={styles.Main} ref={masthead} data-section={'masthead'}>
            <Container>
                <header className={styles.Header}>
                    <a href="https://www.lesechos.fr/" title={'Les Echos'} target={'_blank'}
                       rel={'noopener noreferrer'}>
                        <Images url={EchosLogo} alt={"Supplément partenaire sur Les Echos"} lazy={false}/>
                    </a>
                    <a href="https://www.audemarspiguet.com/fr/watch-collection/code1159byap/#!/welcome"
                       title={'Audemars Piguet'} target={'_blank'} rel={'noopener noreferrer'}>
                        <Images classname={styles.LogoAudemarsPiguet} url={AudemarsLogo} alt={"Audemars Piguet"}
                                lazy={false}
                                figcaption={'Réalisé par'}/>
                    </a>
                </header>

                {
                    SpeakersData().map((speaker, index) => {
                        const speakerId = slugify(speaker.name).toLowerCase();

                        return (
                            <div className={styles.MosaicImage}
                                 style={imagePosition[isMobile ? 'mobile' : 'desktop'][index]}
                                 onMouseEnter={(e) => {
                                     hoverPortrait(e, index)
                                 }}
                                 onMouseLeave={(e) => {
                                     const target = e.target.closest(`.${styles.MosaicImage}`);

                                     TweenMax.to(target, .3, {
                                         scale: 1,
                                         onComplete: () => {
                                             TweenMax.set(target, {
                                                 zIndex: imagePosition.desktop[index].zIndex,
                                             })
                                         }
                                     })
                                 }}
                                 key={speakerId}>
                                <Images url={require(`../images/masthead/${speakerId}.jpg`)}>
                                    <source type={'image/webp'} media={'(max-width: 600px)'}
                                            data-srcset={`${require(`../images/masthead/${speakerId}-mobile.webp`)}`}/>
                                    <source type={'image/jpg'} media={'(max-width: 600px)'}
                                            data-srcset={`${require(`../images/masthead/${speakerId}-mobile.jpg`)}`}/>
                                    <source type={'image/webp'}
                                            data-srcset={`${require(`../images/masthead/${speakerId}@2x.webp`)} 2x, ${require(`../images/masthead/${speakerId}.webp`)} 1x`}/>
                                    <source type={'image/jpeg'}
                                            data-srcset={require(`../images/masthead/${speakerId}.jpg`)}/>
                                </Images>
                            </div>
                        )
                    })
                }

                <span className={styles.MastheadText1} ref={title1}>
                    Classic
                </span>

                <span className={styles.MastheadText2} ref={title2}>
                    with a&nbsp;<strong className={styles.Twist} ref={twist}>twist</strong>
                </span>
            </Container>

            <footer id={'mainFooter'} ref={footer}>
                <span>
                    <small>*Classique avec un twist.</small> Supplément partenaire réalisé et animé par Les Echos Le Parisien Medias, en partenariat avec AUDEMARS
                PIGUET La rédaction des Echos n'a pas participé à sa réalisation.
                </span>
            </footer>

            <Navigation/>

            <ScrollIcon/>
        </section>
    )
}
