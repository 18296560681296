import { createStore } from 'redux';

const initialState = {
    stateInfo: null,
    sb: null
}

const reducer = (state, {type, payload}) => {
    switch (type) {
        case 'REDUCER_NAME':
            return { ...state, stateInfo: payload };
        case 'LOAD_SB':
            return { ...state, sb: payload };
        default:
            return state
    }
};

const store = createStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
