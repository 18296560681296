import React from "react";

export default function ShareIcon ({classname}) {
    const picto = `
<svg width="23px" height="28px" viewBox="0 0 23 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18.4901961,18.5465686 C17.1936275,18.5465686 16.0661765,19.1102941 15.2769608,19.9558824 L8.3995098,15.6151961 C8.7377451,14.995098 8.96323529,14.2622549 8.96323529,13.4730392 C8.96323529,12.8529412 8.8504902,12.2328431 8.56862745,11.7254902 L15.2769608,7.49754902 C16.0661765,8.34313725 17.25,8.90686275 18.4901961,8.90686275 C20.9705882,8.90686275 22.9436275,6.93382353 22.9436275,4.45343137 C22.9436275,1.97303922 20.9705882,0 18.4901961,0 C16.0098039,0 14.0367647,1.97303922 14.0367647,4.45343137 C14.0367647,5.01715686 14.1495098,5.58088235 14.3186275,6.08823529 L7.55392157,10.372549 C6.76470588,9.58333333 5.6372549,9.07598039 4.45343137,9.07598039 C1.97303922,9.07598039 0,11.0490196 0,13.5294118 C0,16.0098039 1.97303922,17.9828431 4.45343137,17.9828431 C5.5245098,17.9828431 6.48284314,17.5882353 7.27205882,16.9681373 L14.375,21.4215686 C14.2058824,21.9289216 14.0931373,22.4926471 14.0931373,23.0563725 C14.0931373,25.5367647 16.0661765,27.5098039 18.5465686,27.5098039 C21.0269608,27.5098039 23,25.5367647 23,23.0563725 C22.9436275,20.5759804 20.9705882,18.5465686 18.4901961,18.5465686 Z" fill="#FFFFFF" fill-rule="nonzero"/>
    </g>
</svg>
    `;

    return (
        <i className={classname || ''} dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
