import {TweenMax} from "gsap";

export default function ImageAnimation(triggerElement, figure, picture, figcaption, image, callback, blocking, sb) {

    const pictureRect = triggerElement.getBoundingClientRect();
    const picturePosPercentage = 100 - (((pictureRect.bottom - window.innerHeight) * 2) * 100 / window.innerHeight * 2);

    if (pictureRect.top <= 0 && blocking) {
        TweenMax.to(image, .3, {
            opacity: 1,
            scale: 1
        })
    }

    // if (picturePosPercentage < 105 && picturePosPercentage > -5) {
        const value = (picturePosPercentage) * .01;
        const opacityValue =
            value < .1 ? 0
                : value > .9 ? 1 : value

        const valueScale = 20 * (picturePosPercentage * .01);
        const valueScaleFinal = .8 + valueScale * .01;

        if (blocking) {
            if (!figure.classList.contains('animated')) {
                TweenMax.set(image, {
                    opacity: opacityValue,
                    scale: valueScaleFinal <= 1 ? valueScaleFinal : 1
                });
            } else {
                TweenMax.to(image, {
                    opacity: 1,
                    scale: 1
                });
            }
        } else {
            TweenMax.set(image, {
                opacity: opacityValue,
                scale: valueScaleFinal <= 1 ? valueScaleFinal : 1
            });
        }

        if (figure.classList.contains('animated') && figcaption) {
            TweenMax.set(figcaption, {
                opacity: opacityValue
            });
        }

        if (figure.getBoundingClientRect().top <= 100 || value >= .7) {
            if (!figure.classList.contains('animated')) {
                if (typeof callback === 'function') {
                    callback();
                }

                figure.classList.add('animated');
            }
        }
    // }
}
