import React from "react";

import styles from "../App.module.scss";

export default function Container({children}) {
    return (
        <div className={styles.Container}>
            {children}
        </div>
    )
}
