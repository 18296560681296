import React, {useEffect, useRef} from "react";
import slugify from "slugify";
import {TweenMax} from "gsap";

import Images from "../Images";
import ArrowIcon from "../Pictos/ArrowIcon";

import styles from "./SpeakerNavigation.module.scss";
import {useSelector} from "react-redux";
import useScrollDirection from "../../hooks/useScrollDirection";

export function NavigationArrow({data, direction}) {
    const speakerId = slugify(data.name).toLowerCase();

    return (
        <React.Fragment>

            {direction === 'previous' ? <ArrowIcon classname={styles.Arrow} direction={'up'}/> : null}
            <Images url={require(`../../images/speakers/${speakerId}/thumbnail.jpg`)}
                    figcaption={data.name}>
                <source type={'image/webp'}
                    data-srcset={`${require(`../../images/speakers/${speakerId}/thumbnail@2x.webp`)} 2x, ${require(`../../images/speakers/${speakerId}/thumbnail.webp`)} 1x`}/>
            </Images>
            {direction === 'next' ? <ArrowIcon classname={styles.Arrow} direction={'down'}/> : null}
        </React.Fragment>
    )
}

export default function SpeakerNavigation({speakerId, previous, next}) {
    const {sb} = useSelector(state => state);

    const navigation = useRef(null);
    const direction = useScrollDirection();

    let prevPos = useRef(0);
    let initialPos = useRef(0);

    useEffect(() => {


        const handleScroll = (status) => {
            const endScrollElement = navigation.current.closest('header').nextElementSibling;
            const navRect = navigation.current.getBoundingClientRect();

            if (sb.isVisible(navigation.current)) {
                if (initialPos.current === 0) {
                    initialPos.current = status.offset.y + navRect.bottom;
                }

                if (direction === 'down') {
                    if (navRect.bottom <= window.innerHeight - (document.getElementById('mainFooter').getBoundingClientRect().height)) {
                        if (!navigation.current.classList.contains('stuck') && prevPos.current === 0) {
                            prevPos.current = status.offset.y;
                            navigation.current.classList.add('stuck');
                            navigation.current.classList.remove('reached');
                        }
                    }

                    if (navRect.bottom >= endScrollElement.children[0].getBoundingClientRect().bottom) {
                        navigation.current.classList.remove('stuck');
                        navigation.current.classList.add('reached');
                    }
                }

                if (direction === 'up') {
                    if (navRect.bottom >= window.innerHeight - (document.getElementById('mainFooter').getBoundingClientRect().height)) {
                        if (!navigation.current.classList.contains('stuck')) {
                            navigation.current.classList.add('stuck');
                            navigation.current.classList.remove('reached');
                        }
                    }

                    if (status.offset.y + navRect.bottom <= initialPos.current) {
                        if (navigation.current.classList.contains('stuck')) {
                            navigation.current.classList.remove('stuck');
                            prevPos.current = 0
                        }
                    }
                }

                if (navigation.current.classList.contains('stuck')) {
                    TweenMax.set(navigation.current, {
                        y: status.offset.y - prevPos.current,
                    })
                }
            }

        };

        if (sb) sb.addListener(handleScroll);

        return (() => {
            if (sb) sb.removeListener(handleScroll);
        })
    }, [sb, direction])

    const navigateHandler = (id) => {
        const toSection = document.querySelector(`[data-section="${id}"]`);

        const sectionScroll = toSection.offsetTop - 100;

        if (sb) {
            sb.scrollTo(0, sectionScroll, 3000)
        }
    };

    return (
        <nav className={styles.Main} ref={navigation}>
            <ul>
                {
                    previous ?
                        <li className={styles.Item}
                            onClick={() => navigateHandler(slugify(previous.name).toLowerCase())}>
                            <NavigationArrow data={previous} direction={'previous'}/>
                        </li>
                        : null
                }

                {
                    next ?
                        <li className={styles.Item} onClick={() => navigateHandler(slugify(next.name).toLowerCase())}>
                            <NavigationArrow data={next} direction={'next'}/>
                        </li>
                        : null
                }
            </ul>
        </nav>
    )
}
