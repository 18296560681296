/*
    @type: function
    @name: initScrollbar
    @use: Initiate the Smooth-scrollbar instance
 */
import Scrollbar from "smooth-scrollbar";

export default function initScrollbar() {
    return Scrollbar.initAll({
        syncCallbacks: true,
        damping: .1,
        speed: 0,
        renderByPixels: true,
        plugins: {
            horizontalScroll: {
                events: [/wheel/]
            },
            overscroll: false,
        }
    });
}
