import React from "react";
import styles from "../Player/PlayButton.module.scss";

export default function PlayIcon ({classname}) {
    const picto = `
<svg class="${classname}" width="28px" height="35px" viewBox="0 0 28 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M13.5640614,4.69211702 L-2.85059877,30.3913707 L29.9787216,30.3913707 L13.5640614,4.69211702 Z" stroke="#000000" transform="translate(13.564061, 17.327309) rotate(-270.000000) translate(-13.564061, -17.327309) "/>
    </g>
</svg>`

    return (
        <div style={{position: 'relative'}}>
            <i className={classname} dangerouslySetInnerHTML={{__html: picto}}/>
            <div className={[styles.Tracker, classname].join(' ')}/>
        </div>
    )
}
