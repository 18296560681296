import React, {useState, useEffect, useRef} from "react";
import {TweenMax} from "gsap";
import {isIE} from "react-device-detect";

import Images from "../Images";
import PlayButton from "../Player/PlayButton";
import Player from "../Player/Player";
import Container from "../Container";

import styles from "./SpeakerContent.module.scss";
import {useSelector} from "react-redux";
import onScreen from "../../functions/onScreen";
import ImageAnimation from "../../functions/ImageAnimation";
import TextAnimation from "../../functions/TextAnimation";

export default function SpeakerContent({data, speakerId}) {
    const {sb} = useSelector(state => state);

    const [player, setPlayer] = useState(false);
    const article = useRef(null);
    const imageBack = useRef(null);
    const row = useRef(null);
    const fullsize = useRef(null);

    useEffect(() => {
        if (!isIE) {
            TweenMax.set(imageBack.current, {
                transformOrigin: 'center top',
                scaleY: 0
            });
        }

        const figureFirst = row.current.querySelectorAll('figure')[0];
        const pictureFirst = figureFirst.querySelector('picture');
        const figcaptionFirst = figureFirst.querySelector('figcaption');
        const imageFirst = figureFirst.querySelector('img');

        const figureSecond = row.current.querySelectorAll('figure')[1];
        const pictureSecond = figureSecond.querySelector('picture');
        const figcaptionSecond = figureSecond.querySelector('figcaption');
        const imageSecond = figureSecond.querySelector('img');

        const figureFullsize = fullsize.current.querySelector('figure');
        const pictureFullsize = figureFullsize.querySelector('picture');
        const figcaptionFullsize = figureFullsize.querySelector('figcaption');
        const imageFullsize = figureFullsize.querySelector('img');

        TweenMax.set([imageFirst, imageSecond, imageFullsize], {
            opacity: 0,
            scale: .8
        });

        const text = article.current.querySelectorAll('p');

        const handleScroll = () => {
            if (sb.isVisible(row.current)) {
                if (onScreen(row.current, true)) {
                    TweenMax.to(imageBack.current, .6, {
                        scaleY: 1
                    });
                }

                ImageAnimation(pictureFirst, figureFirst, pictureFirst, figcaptionFirst, imageFirst, null, true, sb);
                ImageAnimation(pictureSecond, figureSecond, pictureSecond, figcaptionSecond, imageSecond, null, true, sb);
            }

            if (sb.isVisible(fullsize.current)) {
                ImageAnimation(pictureFullsize, figureSecond, pictureFullsize, figcaptionFullsize, imageFullsize, null, true, sb);
            }

            Object.values(text).map(item =>
                sb.isVisible(item) ? TextAnimation(item) : null
            )
        };

        if (sb) sb.addListener(handleScroll);

        return (() => {
            if (sb) sb.removeListener(handleScroll)
        })
    }, [sb]);

    return (
        <article className={styles.Main} ref={article}>
            <Container>
                <div className={styles.Introduction}>
                    <h4>
                        {data.introduction.title}
                    </h4>
                    <p dangerouslySetInnerHTML={{__html: data.introduction.text}}/>
                </div>

                <div className={styles.Row} ref={row}>
                    {
                        data.columns.map((column, index) =>
                            <div className={styles.Column} key={index}>
                                <div className={styles.Content}>
                                    <Images url={require(`../../images/speakers/${speakerId}/${column.image}.jpg`)}>
                                        {
                                            index === 0 ?
                                                <div className={styles.ImageBack}
                                                     ref={imageBack}
                                                     style={{background: data.color}}/>
                                                : null
                                        }
                                        <source type={'image/webp'}
                                            data-srcset={`${require(`../../images/speakers/${speakerId}/${column.image}@2x.webp`)} 2x, ${require(`../../images/speakers/${speakerId}/${column.image}.webp`)} 1x`}/>
                                    </Images>


                                    <div className={styles.Player}>
                                        {
                                            index === 0 ?
                                                <Player {...{data, speakerId}} closeFn={() => setPlayer(false)}/>
                                                : null
                                        }
                                    </div>
                                </div>

                                <div className={styles.Text}>
                                    {
                                        column.text.map((p, indexP) =>
                                            <React.Fragment key={indexP}>
                                                <h4>
                                                    {index === 0 ? "Son savoir-faire" : "Ses couleurs et matières"}
                                                </h4>
                                                <p dangerouslySetInnerHTML={{__html: p}}/>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className={styles.Fullsize} ref={fullsize}>
                    <Images url={require(`../../images/speakers/${speakerId}/profession-illustration.jpg`)}>
                        <source type={'image/webp'}
                            data-srcset={`${require(`../../images/speakers/${speakerId}/profession-illustration@2x.webp`)} 2x, ${require(`../../images/speakers/${speakerId}/profession-illustration.webp`)} 1x`}/>
                    </Images>

                    <div className={styles.Text}>
                        <h4>
                            Le temps dans sa création
                        </h4>
                        {
                            data.fullsize.map((p, index) => <p dangerouslySetInnerHTML={{__html: p}} key={index}/>)
                        }
                    </div>
                </div>
            </Container>
        </article>
    )
}
