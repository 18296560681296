import React, {useEffect, useRef} from "react";
import {isIOS} from "react-device-detect";
import slugify from "slugify";

import Container from "../components/Container";
import Masthead from "../partials/Masthead";
import Speaker from "../components/Speaker/Speaker";
import SpeakersData from "../data/Speakers";

import initScrollbar from "../functions/initScrollbar";

import styles from "./Home.module.scss"
import {useSelector, useDispatch} from "react-redux";
import TextAnimation from "../functions/TextAnimation";

export default function App (){
    const {sb} = useSelector(state => state);
    const dispatch = useDispatch();

    const intro = useRef(null);
    const speakers = useRef(SpeakersData().sort(() => 0.5 - Math.random()));

    /*
         @type: Effect Hook
         @params: none
         @description: Inits the Smooth-scrollbar instance, and stores it.

      */
    useEffect(() => {
        const sb = initScrollbar();

        if (isIOS) document.body.style.overflow = 'hidden';


        if (sb.length > 0) {
            dispatch({type: 'LOAD_SB', payload: sb[0]});
            // sb[0].track.xAxis.element.remove();
        }
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            TextAnimation(intro.current);
        };

        if (sb) sb.addListener(handleScroll);

        return (() => {
            if (sb) sb.removeListener(handleScroll)
        })
    }, [sb]);


    return (
        <main data-scrollbar>
            <Masthead/>

            <Container>
                <p className={styles.Introduction} ref={intro}>
                    Ils sont chef cuisinier, architecte, designer, et directeur artistique renommés. Nous les avons rencontrés pour célébrer les nouveaux modèles Code 11.59 by Audemars Piguet. Chacun à leur façon, ils incarnent le mélange subtil de classicisme et de modernité qui caractérise la collection. <br/>
                    Ces créatifs nous parlent de leur savoir-faire et évoquent les thèmes qui font écho à la Code 11.59 by Audemars Piguet: les couleurs, les nuances, la technicité. <br/>
                    Pour eux tous les domaines artisanaux se rejoignent car y subsiste la même passion, qui ne s’encombre d’aucune temporalité.
                </p>
            </Container>

            {
                speakers.current.map((item, index) => {
                    const previous = speakers.current[index - 1];
                    const next = speakers.current[index + 1];
                    const speakerId = slugify(item.name).toLowerCase();

                    return <Speaker data={item} {...{previous, next, speakerId}} speakerIndex={index+1} key={index}/>
                })
            }
        </main>
    )
}
