import React from "react";

export default function Email () {
    const picto = `
<svg width="23px" height="16px" viewBox="0 0 23 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M1.68292683,0 C1.35211951,0 1.04849146,0.10246392 0.788871951,0.271125953 L10.746189,8.95621712 C11.2020098,9.35424448 11.8163341,9.35418664 12.2713415,8.95621712 L22.211128,0.271125953 C21.9515085,0.10246392 21.6478805,0 21.3170732,0 L1.68292683,0 Z M0.0964317073,1.16582714 C0.0364073171,1.34455337 0,1.5349344 0,1.7352061 L0,13.3032554 C0,13.6517137 0.101564634,13.9718882 0.271736585,14.2431587 L7.39786585,7.54636216 L0.0964317073,1.16582714 Z M22.9035683,1.16582714 L15.6196646,7.53731017 L22.7282634,14.2431587 C22.8984354,13.9718882 23,13.6517137 23,13.3032554 L23,1.7352061 C23,1.5349344 22.9635927,1.34455337 22.9035683,1.16582714 Z M14.7606707,8.29646284 L12.9900915,9.84186631 C12.1337622,10.5909548 10.8751293,10.5898558 10.0186878,9.84186631 L8.25685976,8.30551483 L1.18332195,14.9571382 C1.34160122,15.0076616 1.50812683,15.0384615 1.68292683,15.0384615 L21.3170732,15.0384615 C21.4918732,15.0384615 21.6583988,15.0076616 21.816678,14.9571382 L14.7606707,8.29646284 Z" fill="#FFFFFF" fill-rule="nonzero"/>
    </g>
</svg>`

    return (
        <i dangerouslySetInnerHTML={{__html: picto}}/>
    )
}
